import React from 'react';
import Layout from '../components/Layout';
import TitleAndTheme from '../components/TitleAndTheme';
import Text from '../components/Text';

const NotFoundPage = () => (
  <Layout>
    <TitleAndTheme title="Edwin Morris, 404" themeClass="theme--index" />

    <Text noBottomMargin>
      <p>
        This url doesn’t go anywhere. Try choosing a project from the list
        below:
      </p>
    </Text>
  </Layout>
);

export default NotFoundPage;
